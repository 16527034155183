import React from "react";
import { string, bool, any } from "prop-types";

import { Spacing } from "./styles";

const Spacer = ({ anchorid, bottomOnly, children }) => {
  return (
    <Spacing
      id={anchorid ? anchorid : null}
      className={!bottomOnly ? "" : "bottom-only"}
    >
      {children}
    </Spacing>
  );
};

Spacer.propTypes = {
  anchorid: string,
  bottomOnly: bool,
  children: any,
};

Spacer.defaultProps = {
  anchorid: null,
  bottomOnly: false,
  children: "",
};

export default Spacer;
