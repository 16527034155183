import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";

export const HeroWrapper = styled.section`
  height: calc(100vh - 82px);
  margin: 82 auto 0;
  max-width: 2200px;
  overflow: hidden;
  position: relative;
  width: 100vw;

  @media (min-width: 768px) {
    height: calc(100vh - 80px);
  }
`;

export const Mask = styled.div`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
`;

export const Content = styled.div`
  bottom: 0;
  left: 0;
  padding: 1.5rem 0;
  position: absolute;
  text-align: left;
  width: 100vw;

  @media (min-width: 576px) {
    padding: 3rem;
  }

  h1,
  p {
    color: ${(props) => `${props.theme.colors.white}`};
  }
`;

export const BackgroundImageStyled = styled(BackgroundImage)`
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 80%;
  margin-left: 10%;
`;
