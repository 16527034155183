import React, { useEffect } from "react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import axios from "axios";

import { withTrans } from "./../../i18e/withTrans";

import { ContactForm } from "./styles";

export const Contact = ({ t, i18n }) => {
  const schema = Yup.object({
    firstName: Yup.string().required(t(`contact.validation.firstNameRequired`)),
    lastName: Yup.string().required(t(`contact.validation.lastNameRequired`)),
    email: Yup.string()
      .required(t(`contact.validation.emailRequired`))
      .email(t(`contact.validation.emailInvalid`)),
    phone: Yup.string().required(t(`contact.validation.phoneRequired`)),
    subject: Yup.string().required(t(`contact.validation.subjectRequired`)),
    message: Yup.string().required(t(`contact.validation.messageRequired`)),
  });

  const handleSubmit = (model, { setSubmitting, resetForm }) => {
    const { firstName, lastName, email, phone, subject, message } = model;

    const data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      subject: subject,
      message: message,
    };

    // DO NOT DELETE:
    // I have used SUCCESFULLY this curl script to send a message from curl:
    // curl -v -X POST \
    // 	--header "Content-Type: application/json" --header "Access-Control-Allow-Origin: '*'"   --header "Access-Control-Allow-Headers : Content-Type" --header "Access-Control-Allow-Methods: OPTIONS,POST" \
    // 	--data '{"firstName":"Alonso","lastName": "Baldioceda","email":"alonsojose09@hotmail.com","phone":"(506) 83274040","subject":"Testing the form","message": "This is just some text to test function"}' \
    //   https://kzet1qk47i.execute-api.us-east-1.amazonaws.com/prod/contact

    const config = {
      method: "post",
      url: `${process.env.GATSBY_AWS_API_GATEWAY}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(data),
    };

    axios(config)
      .then(function (response) {
        if (response.status === 200) {
          resetForm();
          Swal.fire(
            t(`contact.validation.successHeader`),
            t(`contact.validation.successBody`),
            "success"
          );
        }
      })
      .catch(function (error) {
        setSubmitting(false);
        if (error) {
          Swal.fire(
            t(`contact.validation.errorHeader`),
            t(`contact.validation.errorBody`),
            "error"
          );
        }
      });
  };

  useEffect(() => {
    handleSubmit;
  }, []);

  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      }}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        isSubmitting,
      }) => (
        <ContactForm>
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} md="6">
                <Form.Label htmlFor="firstName-input">
                  {t(`contact.firstName`)}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t(`contact.firstName`)}
                  name="firstName"
                  id="firstName-input"
                  value={values.firstName}
                  onChange={handleChange}
                  isValid={touched.firstName && !errors.firstName}
                />
                {errors.firstName && touched.firstName ? (
                  <p className="invalid">{errors.firstName}</p>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label htmlFor="lastName-input">
                  {t(`contact.lastName`)}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t(`contact.lastName`)}
                  name="lastName"
                  id="lastName-input"
                  value={values.lastName}
                  onChange={handleChange}
                  isValid={touched.lastName && !errors.lastName}
                />
                {errors.lastName && touched.lastName ? (
                  <p className="invalid">{errors.lastName}</p>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label htmlFor="email-input">
                  {t(`contact.email`)}
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder={t(`contact.email`)}
                  name="email"
                  id="email-input"
                  value={values.email}
                  onChange={handleChange}
                  isValid={touched.email && !errors.email}
                />
                {errors.email && touched.email ? (
                  <p className="invalid">{errors.email}</p>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label htmlFor="phone-input">
                  {t(`contact.phone`)}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t(`contact.phone`)}
                  name="phone"
                  id="phone-input"
                  value={values.phone}
                  onChange={handleChange}
                  isValid={touched.phone && !errors.phone}
                />
                {errors.phone && touched.phone ? (
                  <p className="invalid">{errors.phone}</p>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="subject-input">
                  {t(`contact.subject`)}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t(`contact.subject`)}
                  name="subject"
                  id="subject-input"
                  value={values.subject}
                  onChange={handleChange}
                  isValid={touched.subject && !errors.subject}
                />
                {errors.subject && touched.subject ? (
                  <p className="invalid">{errors.subject}</p>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="message-input">
                  {t(`contact.message`)}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="message"
                  id="message-input"
                  rows={3}
                  value={values.message}
                  onChange={handleChange}
                  isValid={touched.message && !errors.message}
                />

                {errors.message && touched.message ? (
                  <p className="invalid">{errors.message}</p>
                ) : null}
              </Form.Group>
              <Col>
                <Button
                  variant="dark"
                  disabled={!isValid || isSubmitting}
                  type="submit"
                >
                  {isSubmitting ? t(`contact.submitting`) : t(`contact.submit`)}
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </ContactForm>
      )}
    </Formik>
  );
};

export default withTrans(Contact);
