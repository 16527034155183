import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Item from "@components/Item";

import { withTrans } from "./../../i18e/withTrans";

import {
  Wifi,
  Washing,
  Dryer,
  AirConditioner,
  // HotWater,
  Towels,
  Footprint,
  Parking,
  Kitchen,
  Oven,
  Silverware,
  Refrigerator,
  Stove,
  CoffeeMaker,
  Oil,
  BarbecueUtensils,
  Pillow,
  BedLinens,
  BodySoap,
  HairDryer,
  Patio,
  Garden,
  Backyard,
  Grill,
  WoodFire,
} from "./styles";

const Services = ({ t, i18n }) => {
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <div className="mb-4 mb-lg-5">
            <h3>{t(`services.headingBasics`)}</h3>
          </div>
        </Col>
        <Item id="services.wifi" icon={<Wifi />} />
        <Item
          id="services.essentialServices"
          icon={<Towels />}
          details="services.essentialServicesDetails"
        />
        <Item id="services.air" icon={<AirConditioner />} />
        {/* <Item id="services.hotWater" icon={<HotWater />} /> */}
      </Row>
      <Row>
        <Col xs={12}>
          <div className="my-4 my-lg-5">
            <h3>{t(`services.headingInstallations`)}</h3>
          </div>
        </Col>
        <Item id="services.parking" icon={<Parking />} />
        <Item id="services.petsFriedly" icon={<Footprint />} />
      </Row>
      <Row>
        <Col xs={12}>
          <div className="my-4 my-lg-5">
            <h3>{t(`services.headingKitchen`)}</h3>
          </div>
        </Col>
        <Item
          id="services.kitchen"
          icon={<Kitchen />}
          details="services.kitchenDetails"
        />
        <Item id="services.oven" icon={<Oven />} />
        <Item id="services.dishesAndSilverware" icon={<Silverware />} />
        <Item id="services.refrigerator" icon={<Refrigerator />} />
        <Item id="services.stove" icon={<Stove />} />
        <Item id="services.coffeeMaker" icon={<CoffeeMaker />} />
        <Item
          id="services.cookingBasics"
          icon={<Oil />}
          details="services.cookingBasicsDetails"
        />
        <Item id="services.barbecueUtensils" icon={<BarbecueUtensils />} />
      </Row>
      <Row>
        <Col xs={12}>
          <div className="my-4 my-lg-5">
            <h3>{t(`services.headingBedAndBath`)}</h3>
          </div>
        </Col>
        <Item id="services.extraPillowsAndBlankets" icon={<Pillow />} />
        <Item id="services.bedLinens" icon={<BedLinens />} />
        <Item id="services.bodySoap" icon={<BodySoap />} />
        {/* <Item id="services.hairDryer" icon={<HairDryer />} /> */}
      </Row>
      <Row>
        <Col xs={12}>
          <div className="my-4 my-lg-5">
            <h3>{t(`services.headingOutdoor`)}</h3>
          </div>
        </Col>
        <Item id="services.patio" icon={<Patio />} />
        <Item id="services.garden" icon={<Garden />} />
        <Item id="services.backyard" icon={<Backyard />} />
        <Item id="services.grill" icon={<Grill />} />
        {/* <Item id="services.woodFire" icon={<WoodFire />} /> */}
      </Row>
    </Container>
  );
};

export default withTrans(Services);
