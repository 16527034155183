import React from "react";
import { string, object } from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";

import { withTrans } from "./../../i18e/withTrans";

import { HeroWrapper, BackgroundImageStyled, Mask, Content } from "./styles";

const Hero = ({ imgSrc, imgMdUpSrc, title, description, t, i18n }) => {
  const imageSrc = getImage(imgSrc);
  const imageMdUpSrc = getImage(imgMdUpSrc);

  const bgImageSrc = convertToBgImage(imageSrc);
  const bgImageMdUpSrc = convertToBgImage(imageMdUpSrc);

  return (
    <HeroWrapper>
      <BackgroundImageStyled
        Tag="section"
        {...bgImageSrc}
        backgroundColor={`#040e18`}
        className="d-block d-md-none background-image"
      />
      <BackgroundImageStyled
        Tag="section"
        {...bgImageMdUpSrc}
        backgroundColor={`#040e18`}
        className="d-none d-md-block background-image"
      />
      <Mask />
      <Content>
        <Container fluid>
          <Row>
            <Col lg={8} xl={6} style={ {marginLeft: '10%' }}>
              <h1 style={ { fontSize: 30, color: '#17a2b8', textShadow: '2px 2px #343a40' } } className="mb-4">{t(`${title}`)}</h1>
              {/* <p>{t(`${description}`)}</p> */}
              <a
                href="https://beds24.com/booking2.php?propid=112216"
                className="btn btn-danger d-md-inline-block mt-4 px-5 py-2"
              >
                <span className="text-capitalize font-weight-bold pr-2">
                  {t(`booking`)}
                </span>
              </a>
            </Col>
          </Row>
        </Container>
      </Content>
    </HeroWrapper>
  );
};

Hero.propTypes = {
  imgSrc: object.isRequired,
  imgMdUpSrc: object.isRequired,
  title: string,
  description: string,
};

Hero.defaultProps = {
  imgSrc: {},
  imgMdUpSrc: {},
  title: "",
  description: "",
};

export default withTrans(Hero);
