import styled from "styled-components";

export const Home = styled.div`
  .units {
    .accommodation {
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      @media (min-width: 992px) {
        &:nth-last-child(-n + 2) {
          margin-bottom: 0;
        }
      }
    }
  }
`;
