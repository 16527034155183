import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Item from "@components/Item";

import { CheckIn, CheckOut, Smoke, Party } from "./styles";

const Rules = () => (
  <Container>
    <Row>
      <Item id="rules.checkIn" icon={<CheckIn />} />
      <Item id="rules.checkOut" icon={<CheckOut />} />
      <Item id="rules.smoke" icon={<Smoke />} />
      {/* <Item id="rules.party" icon={<Party />} /> */}
    </Row>
  </Container>
);

export default Rules;
