import React from "react";
import Masonry from "react-masonry-component";
import styled from "styled-components";

export const Content = styled(Masonry)`
  display: flex;
  justify-content: space-between;

  .gallery-item {
    margin: 0 0.5% 0.5%;
    width: 49%;

    @media (min-width: 992px) {
      width: 24%;
    }
  }
`;
