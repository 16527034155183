import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Layout from "@components/Layout";
import Hero from "@components/Hero";
import Spacer from "@components/Spacer";
import Card from "@components/Card";
import Album from "@components/Album";
import Services from "@components/Services";
import Contact from "@components/Contact";
import Rules from "@components/Rules";

import { withTrans } from "./../../i18e/withTrans";

import { studioUnitsAlbum, superiorUnitsAlbum, commonAlbum } from "./../../modules/constants";

import { Home } from "./styles";

const IndexPage = ({ data, t, i18n }) => {
  const { homeTopImage, homeTopMdUpImage, studioUnits, superiorUnits, commonAreas, allContentJson } =
    data || [];

  const { villas } = allContentJson.edges[0].node;
  const { distribution: villasDistribution } = villas;

  return (
    <Home>
      <Layout>
        <Hero
          imgSrc={homeTopImage}
          imgMdUpSrc={homeTopMdUpImage}
          title="hero.title"
          description="hero.description"
          className="mb-3 mb-lg-4"
        />
        <section>
          <div className="bg-white">
            <Container>
              <Row>
                <Col xs={12}>
                  <Spacer anchorid="sleeping-arrangements">
                    <h2 className="m-0">{t(`arrangements.heading`)}</h2>
                  </Spacer>
                </Col>
              </Row>
            </Container>
            <Spacer bottomOnly={true}>
              <div className="units">
                <Container>
                  <Row>
                    {villasDistribution.map((item, indexCabin) => (
                      <Col md={6} key={indexCabin} className="accommodation">
                        <Card data={item} index={indexCabin} />
                      </Col>
                    ))}
                  </Row>
                </Container>
              </div>
            </Spacer>
          </div>
        </section>
        <section>
          <div className="bg-merino">
            <Container>
              <Row>
                <Col sm={12}>
                  <Spacer anchorid="units">
                    <h2 className="mb-5">{t(`units.heading`)}</h2>
                    <p className="mb-0">{t(`units.description`)}</p>
                  </Spacer>
                  <Spacer bottomOnly={true} anchorid="units">
                    <h3 className="mb-5">{t(`units.studioVilla`)}</h3>
                    <p className="mb-0">{t(`units.studioVillaDescription`)}</p>
                  </Spacer>
                  <Spacer bottomOnly={true}>
                    <div className="border-white">
                      <Album images={studioUnits} photos={studioUnitsAlbum} />
                    </div>
                  </Spacer>
                  <Spacer bottomOnly={true} anchorid="units">
                    <h3 className="mb-5">{t(`units.superiorVilla`)}</h3>
                    <p className="mb-0">{t(`units.superiorVillaDescription`)}</p>
                  </Spacer>
                  <Spacer bottomOnly={true}>
                    <div className="border-white">
                      <Album images={superiorUnits} photos={superiorUnitsAlbum} />
                    </div>
                  </Spacer>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        <section>
          <div className="bg-white">
            <Container>
              <Row>
                <Col sm={12}>
                  <Spacer anchorid="common-spaces">
                    <h2 className="mb-5">{t(`commonSpaces.heading`)}</h2>
                    <p className="mb-3">{t(`commonSpaces.descriptionOne`)}</p>
                    <p className="mb-0">{t(`commonSpaces.descriptionTwo`)}</p>
                  </Spacer>
                  <Spacer bottomOnly={true}>
                    <div className="common">
                      <div className="border-athens-gray">
                        <Album images={commonAreas} photos={commonAlbum} />
                      </div>
                    </div>
                  </Spacer>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        <section>
          <div className="bg-gin">
            <Container>
              <Row>
                <Col sm={12}>
                  <Spacer anchorid="services">
                    <h2 className="m-0">{t(`services.heading`)}</h2>
                  </Spacer>
                </Col>
              </Row>
            </Container>
            <Spacer bottomOnly={true}>
              <Services />
            </Spacer>
          </div>
        </section>
        <section>
          <div className="bg-white">
            <Container>
              <Row>
                <Col sm={12}>
                  <Spacer anchorid="location">
                    <h2 className="m-0">{t(`location.heading`)}</h2>
                  </Spacer>
                  <Spacer bottomOnly={true}>
                    <iframe
                      title="Villas Cabuya Location"
                      src="https://maps.google.com/maps?q=villas%20cabuya&t=&z=11&ie=UTF8&iwloc=&output=embed"
                      width="100%"
                      height="500px"
                      frameBorder="0"
                      allowFullScreen=""
                      aria-hidden="false"
                      tabIndex="0"
                      className="white-border"
                    ></iframe>
                  </Spacer>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        <section>
          <div className="bg-merino">
            <Container>
              <Row>
                <Col sm={12}>
                  <Spacer anchorid="contact">
                    <h2 className="m-0">{t(`contact.heading`)}</h2>
                  </Spacer>
                  <Spacer bottomOnly={true}>
                    <Contact />
                  </Spacer>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        <section>
          <div className="bg-white">
            <Container>
              <Row>
                <Col sm={12}>
                  <Spacer anchorid="rules">
                    <h2 className="m-0">{t(`rules.heading`)}</h2>
                  </Spacer>
                </Col>
              </Row>
            </Container>
            <Spacer bottomOnly={true}>
              <Rules />
            </Spacer>
          </div>
        </section>
      </Layout>
    </Home>
  );
};

export default withTrans(IndexPage);
