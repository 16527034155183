import React from "react";
import styled, { css } from "styled-components";

import BedBunkIcon from "@svgs/bed-bunk.svg";
import BedSingleIcon from "@svgs/bed-single.svg";
import BedIQueenIcon from "@svgs/bed-queen.svg";
import SofaBedIcon from "@svgs/bed-queen.svg";

export const StyledCard = styled.div`
  border: ${(props) => `4px solid ${props.theme.colors.athensGray};`};

  @media (min-width: 768px) {
    min-height: 298px;
  }

  .card-body {
    .circle {
      background-color: ${(props) => `${props.theme.colors.gin};`};
      border-radius: 50%;
      height: 52px;
      margin-right: 0.75rem;
      width: 52px;

      svg {
        fill: ${(props) => `${props.theme.colors.black};`};
      }
    }
  }
`;

export const BedBunk = styled((props) => <BedBunkIcon {...props} />)`
  height: 28px;
  width: 28px;
`;

export const BedSingle = styled((props) => <BedSingleIcon {...props} />)`
  height: 28px;
  width: 28px;
`;

export const BedQueen = styled((props) => <BedIQueenIcon {...props} />)`
  height: 28px;
  width: 28px;
`;

export const SofaBed = styled((props) => <SofaBedIcon {...props} />)`
  height: 28px;
  width: 28px;
`;
