import styled from "styled-components";

export const Spacing = styled.div`
  padding: 2.5rem 0;

  @media (min-width: 768px) {
    padding: 3.5rem 0;
  }

  @media (min-width: 992px) {
    padding: 4.5rem 0;
  }

  &.bottom-only {
    padding: 0 0 2.5rem;

    @media (min-width: 768px) {
      padding: 0 0 3.5rem;
    }

    @media (min-width: 992px) {
      padding: 0 0 4.5rem;
    }
  }
`;
