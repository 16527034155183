import React, { useState } from "react";
import { array, object, bool } from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Lightbox from "react-image-lightbox";

import "react-image-lightbox/style.css";

import { Content } from "./styles";

const Album = ({ images, photos }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const arrayLength = images.edges.length;

  const arrImg = images.edges.map((image) => {
    return [
      image.node,
      image.node.childImageSharp.gatsbyImageData.images.fallback.src,
    ];
  });

  const sorter = (a, b) => {
    return parseInt(a[1].split("/").pop()) - parseInt(b[1].split("/").pop());
  };

  const arrImgSorted = arrImg.sort(sorter);

  const openLightbox = (event) => {
    event.preventDefault();
    setIsOpen(true);
    setPhotoIndex(parseInt(event.target.getAttribute("data-key")));
  };

  const pictures = arrImgSorted.map((img, index) => {
    return (
      <a
        href="/#"
        onClick={(e) => openLightbox(e)}
        key={img[0].id}
        className="gallery-item"
        // alt={photos[index].alt}
        // aria-label={`Gallery Image - ${photos[index].alt}`}
      >
        <GatsbyImage
          image={getImage(img[0].childImageSharp)}
          alt=""
          data-key={index}
        />
      </a>
    );
  });

  const current = photoIndex;
  const next = (photoIndex + 1) % arrayLength;
  const prev = (photoIndex + arrayLength - 1) % arrayLength;

  // console.log("array: ", arrImgSorted[current][1]);

  return (
    <>
      {/* <GatsbyImage
        image={getImage(img.node.childImageSharp)}
        alt=""
        data-key={index}
      /> */}

      <Content elementType={"div"} className={"gallery"}>
        {pictures.map((picture) => picture)}
      </Content>
      {isOpen && (
        <Lightbox
          mainSrc={`${arrImgSorted[current][1]}`}
          nextSrc={`${arrImgSorted[next][1]}`}
          prevSrc={`${arrImgSorted[prev][1]}`}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex(prev)}
          onMoveNextRequest={() => setPhotoIndex(next)}
        />
      )}
    </>
  );
};

Album.propTypes = {
  images: object.isRequired,
  photos: array.isRequired,
  borderWhite: bool,
};

Album.defaultProps = {
  images: {},
  photos: [],
  bool: false,
};

export default Album;
