import React from "react";
import { graphql } from "gatsby";

import Homepage from "@compositions/Homepage";

const IndexPage = ({ data }) => {
  return <Homepage data={data} />;
};

export const query = graphql`
  query {
    allContentJson {
      edges {
        node {
          villas {
            distribution {
              bunk
              queen
              sofabed
            }
          }
        }
      }
    }
    homeTopImage: file(relativePath: { eq: "hero2.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    }
    homeTopMdUpImage: file(relativePath: { eq: "hero2.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 1100)
      }
    }
    studioUnits: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "studio_units" }
      }
      sort: { order: ASC, fields: name }
    ) {
      totalCount
      edges {
        node {
          base
          name
          id
          childImageSharp {
            gatsbyImageData(width: 900)
          }
        }
      }
    }
    superiorUnits: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "superior_units" }
      }
      sort: { order: ASC, fields: name }
    ) {
      totalCount
      edges {
        node {
          base
          name
          id
          childImageSharp {
            gatsbyImageData(width: 900)
          }
        }
      }
    }
    commonAreas: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "common" }
      }
      sort: { order: ASC, fields: name }
    ) {
      totalCount
      edges {
        node {
          base
          name
          id
          childImageSharp {
            gatsbyImageData(width: 900)
          }
        }
      }
    }
  }
`;

export default IndexPage;
