import React from "react";
import styled, { css } from "styled-components";

import CheckInIcon from "@svgs/clock.svg";
import CheckOutIcon from "@svgs/clock.svg";
import SmokeIcon from "@svgs/no-smoking.svg";
import PartyIcon from "@svgs/party.svg";

const IconBase = css`
  margin-right: 14px;
`;

export const CheckIn = styled((props) => <CheckInIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 26px;
`;

export const CheckOut = styled((props) => <CheckOutIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 28px;
`;

export const Smoke = styled((props) => <SmokeIcon {...props} />)`
  ${IconBase}

  height: 26px;
  width: 26px;
`;

export const Party = styled((props) => <PartyIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 28px;
`;
