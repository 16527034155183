import React from "react";
import { string, any } from "prop-types";
import Col from "react-bootstrap/Col";

import { withTrans } from "./../../i18e/withTrans";

import { Icon, Content } from "./styles";

const Item = ({ icon, id, details, t, i18n }) => {
  return (
    <Col md={6} xl={4}>
      <div className="d-flex mb-4">
        <Icon>{icon}</Icon>
        <Content>
          {t(`${id}`)}
          {details && <span className="d-block">{t(`${details}`)}</span>}
        </Content>
      </div>
    </Col>
  );
};

Item.propTypes = {
  id: string.isRequired,
  icon: any,
  details: string,
};

Item.defaultProps = {
  icon: "",
  details: null,
};

export default withTrans(Item);
