import React from "react";
import { object } from "prop-types";

import { withTrans } from "./../../i18e/withTrans";

import { StyledCard, BedBunk, BedQueen } from "./styles";

const Card = ({ data, index, t, i18n }) => {
  const { bunk, queen, sofabed } = data || 0;

  const bn = parseInt(bunk);
  const qn = parseInt(queen);
  const sb = parseInt(sofabed);

  return (
    <StyledCard className="card d-flex flex-column align-items-center">
      <h3 className="mb-0 py-4 text-capitalize bg-gin w-100 text-center">
        {`${t(`cottage`)} ${parseInt(index) + 1}`}
      </h3>
      <div className="card-body px-4 pb-4">
        {bn >= 1 ? (
          <div className="d-flex align-items-center mb-2">
            <div className="circle d-flex align-items-center justify-content-center">
              <BedBunk />
            </div>
            <p className="p-small mb-0">
              {`${bn} ${bn === 1 ? t(`bunk`) : t(`bunks`)}`}
            </p>
          </div>
        ) : (
          ""
        )}
        {qn >= 1 ? (
          <div className="d-flex align-items-center mb-2">
            <div className="circle d-flex align-items-center justify-content-center">
              <BedQueen />
            </div>
            <p className="p-small mb-0">
              {`${qn} ${qn === 1 ? t(`bed`) : t(`beds`)} ${t(`queen`)}`}
            </p>
          </div>
        ) : (
          ""
        )}
        {sb >= 1 ? (
          <div className="d-flex align-items-center">
            <div className="circle d-flex align-items-center justify-content-center">
              <BedQueen />
            </div>
            <p className="p-small mb-0">
              {`${sb} ${sb === 1 ? t(`sofabed`) : t(`sofabeds`)} ${t(`queen`)}`}
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
    </StyledCard>
  );
};

Card.propTypes = {
  data: object.isRequired,
};

Card.defaultProps = {
  data: {},
};

export default withTrans(Card);
