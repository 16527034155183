import React from "react";
import styled, { css } from "styled-components";

import WifiIcon from "@svgs/wifi.svg";
import WashingIcon from "@svgs/washing.svg";
import DryerIcon from "@svgs/dryer.svg";
import AirConditionerIcon from "@svgs/air-conditioner.svg";
// import HotWaterIcon from "@svgs/hot-water.svg";
import TowelsIcon from "@svgs/towels.svg";
import ParkingIcon from "@svgs/parking.svg";
import FootprintIcon from "@svgs/footprint.svg";
import KitchenIcon from "@svgs/kitchen.svg";
import OvenIcon from "@svgs/oven.svg";
import SilverwareIcon from "@svgs/silverware.svg";
import RefrigeratorIcon from "@svgs/refrigerator.svg";
import StoveIcon from "@svgs/stove.svg";
import CoffeeMakerIcon from "@svgs/coffee-maker.svg";
import OilIcon from "@svgs/oil.svg";
import BarbecueUtensilsIcon from "@svgs/barbecue-utensils.svg";
import PillowIcon from "@svgs/pillow.svg";
import BedLinensIcon from "@svgs/bed-linens.svg";
import BodySoapIcon from "@svgs/shampoo.svg";
import HairDryerIcon from "@svgs/hair-dryer.svg";
import PatioIcon from "@svgs/patio.svg";
import GardenIcon from "@svgs/garden.svg";
import BackyardIcon from "@svgs/backyard.svg";
import GrillIcon from "@svgs/grill.svg";
import WoodFireIcon from "@svgs/wood-fire.svg";

const IconBase = css`
  margin-right: 14px;
`;

export const Wifi = styled((props) => <WifiIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 26px;
`;

export const Washing = styled((props) => <WashingIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 28px;
`;

export const Dryer = styled((props) => <DryerIcon {...props} />)`
  ${IconBase}

  height: 26px;
  width: 26px;
`;

export const AirConditioner = styled((props) => (
  <AirConditionerIcon {...props} />
))`
  ${IconBase}

  height: 28px;
  width: 28px;
`;

// export const HotWater = styled((props) => <HotWaterIcon {...props} />)`
//   ${IconBase}

//   height: 26px;
//   width: 26px;
// `;

export const Towels = styled((props) => <TowelsIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 28px;
`;

export const Parking = styled((props) => <ParkingIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 28px;
`;

export const Footprint = styled((props) => <FootprintIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 28px;
`;

export const Kitchen = styled((props) => <KitchenIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 28px;
`;

export const Oven = styled((props) => <OvenIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 28px;
`;

export const Silverware = styled((props) => <SilverwareIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 28px;
`;

export const Refrigerator = styled((props) => <RefrigeratorIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 28px;
`;

export const Stove = styled((props) => <StoveIcon {...props} />)`
  ${IconBase}

  height: 32px;
  width: 32px;
`;

export const CoffeeMaker = styled((props) => <CoffeeMakerIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 28px;
`;

export const Oil = styled((props) => <OilIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 28px;
`;

export const BarbecueUtensils = styled((props) => (
  <BarbecueUtensilsIcon {...props} />
))`
  ${IconBase}

  height: 28px;
  width: 28px;
`;

export const Pillow = styled((props) => <PillowIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 28px;
`;

export const BedLinens = styled((props) => <BedLinensIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 28px;
`;

export const BodySoap = styled((props) => <BodySoapIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 28px;
`;

export const HairDryer = styled((props) => <HairDryerIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 28px;
`;

export const Patio = styled((props) => <PatioIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 28px;
`;

export const Garden = styled((props) => <GardenIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 28px;
`;

export const Backyard = styled((props) => <BackyardIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 28px;
`;

export const Grill = styled((props) => <GrillIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 28px;
`;

export const WoodFire = styled((props) => <WoodFireIcon {...props} />)`
  ${IconBase}

  height: 28px;
  width: 28px;
`;
