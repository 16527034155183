import styled from "styled-components";

export const Content = styled.p`
  margin-bottom: 0;

  span {
    font-size: 1rem;
    font-weight: 300;
  }
`;

export const Icon = styled.div`
  width: 44px;
`;
