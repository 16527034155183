const default_values = (index) => {
  return (
    {
      id: index,
      alt: "some alt here",
      height: 3,
      width: 4
    }
  )
};

export const studioUnitsAlbum = [...Array(14).keys()].map(i => default_values(i));
export const superiorUnitsAlbum = [...Array(9).keys()].map(i => default_values(i));
export const commonAlbum = [...Array(12).keys()].map(i => default_values(i));
